
const authDomainUrl = 'https://cummins-stg-pool.auth.us-east-1.amazoncognito.com';
const siteDomainUrl = 'https://servicepoint-stg.cummins.com';
const responseType = 'code';
const scope = 'openid+profile';
const appId = '32935';
const clientId = '4c0f49ulrmnnad8bm54el5c0g1';
const redirectUri = 'https://servicepoint-stg.cummins.com/login';

export const environment = {
  // appId: `${appId}`,
  mwApiHostUrl: 'https://cssna-apps-stg.cummins.com/api/mitchell-one',
  MWHostURL: 'https://api-ue-stgnf3-aw-ex.cummins.com',
  mitchellOneIntegrationUrl: 'https://cssna-apps-stg.cummins.com',
  intentAction: 'mitchell1.intent.action.pick',
  intentView: 'vendor/vnd.pdt.partslabor.pick.v2',
  redirectUrlValue: `${redirectUri}`,
  codeToTokenServiceUrl: `${authDomainUrl}/oauth2/token`,
  contentTypeKey: 'Content-Type',
  contentTypeValue: 'application/x-www-form-urlencoded',
  // logoutUrl: `${authDomainUrl}/logout?client_id=${clientId}&logout_uri=https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0&redirect_uri=${redirectUri}&response_type=${responseType}`,
  // authentication_url: `${authDomainUrl}/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=AzureAD`,
  // oam_logout_url: 'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0',

  production: false,
  gtmKey: 'GTM-WBCN487',
  gtmAuth:'JpJpUPrvjVKpZB79dVDCXg&gtm_preview=env-3&gtm_cookies_win=x',
  quoteApiHostUrl: 'https://cssna-apps-dev.cummins.com/api/inspections',

  // local: true,
  // quoteApiHostUrl: 'https:/d2hs8leq8aw7tz.cloudfront.net/api/inspections',
  inspectionUrl: 'https://servicepoint-stg.cummins.com/',
  inspectionApiUrl: 'https://api.servicepoint-stg.cummins.com/api/inspections',
  s3Domain: 'cssna-inspections-stg.s3.amazonaws.com',
  siteDomain: `${siteDomainUrl}`,
  oauth2: {
    loginUrl: `${authDomainUrl}/oauth2/authorize`,
    redirectUrl: `${siteDomainUrl}/login`,
    tokenUrl: `${authDomainUrl}/oauth2/token`,
    clientId: '4c0f49ulrmnnad8bm54el5c0g1',
    scope: 'openid+profile',  // +https://servicepoint-stg.cummins.com/web',
    responseType: 'code',
    grantType: 'authorization_code',
    logoutUrl: `${authDomainUrl}/logout`,
    logoutRedirectUrl: `${siteDomainUrl}/logout`
  },
};
